@import "./vars";

body {
  overflow: visible !important;
  overflow-x: hidden !important;
}

#root{
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

:root {
  --webkit-scale-1: scale(1);

  --etpay-color-orion: #{$text-color};
  --etpay-color-moon: #{$color-moon};
  --etpay-color-vanth: #{$color-vanth};
  --etpay-color-pure: #{$color-light};
  --etpay-color-secondary: #{$color-secondary};
  --etpay-color-sirus: #{$color-sirus};
  --etpay-color-canes: #{$color-canes};
  --etpay-color-purple: #{$color-purple};
  --etpay-color-border: #{$color-border};
  --etpay-color-gray: #{$color-gray};
  --etpay-color-half-black: #{$color-half-black};
  --etpay-color-less-brown: #{$color-less-brown};
  --etpay-color-blue: #{$color-blue};
  --etpay-color-ivory: #{$color-ivory};
  --etpay-color-button: #{$color-button};

  --etpay-font-oatmeal: #{$font-oatmeal};
  --etpay-font-oatmeal-regular: #{$font-oatmeal-regular};
  --etpay-display-flex: #{$display-flex};
  --etpay-flex-direction-column: #{$flex-direction-column};
  --etpay-flex-direction-row: #{$flex-direction-row};
  --etpay-center-element: #{$center-element};
  --etpay-display-grid: #{$display-grid};
  --etpay-grid-template-columns: #{$desktop-grid-template-columns};
  --etpay-grid-gap: #{$desktop-grid-gap};

  --etpay-font-size-07: #{$font-size-07};
  --etpay-font-size-08: #{$font-size-08};
  --etpay-font-size-10: #{$font-size-10};
  --etpay-font-size-11: #{$font-size-11};
  --etpay-font-size-12: #{$font-size-12};
  --etpay-font-size-13: #{$font-size-13};
  --etpay-font-size-13-1: #{$font-size-13-1};
  --etpay-font-size-15: #{$font-size-15};
  --etpay-font-size-20: #{$font-size-20};
  --etpay-font-size-21: #{$font-size-21};
  --etpay-font-size-23: #{$font-size-23};
  --etpay-font-size-26: #{$font-size-26};
  --etpay-font-size-40: #{$font-size-40};
  --etpay-font-size-63: #{$font-size-63};

  --etpay-position-absolute: #{$position-absolute};
  --etpay-position-relative: #{$position-relative};


  // nuevas variables
  --etpay-font-size-18: 1.625rem;
}

@font-face {
  font-family: "Oatmeal Pro";
  src: url("fonts/oatmeal-pro/OatmealPro-Regular.eot");
  src: url("fonts/oatmeal-pro/OatmealPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/oatmeal-pro/OatmealPro-Regular.woff2") format("woff2"),
    url("fonts/oatmeal-pro/OatmealPro-Regular.woff") format("woff"),
    url("fonts/oatmeal-pro/OatmealPro-Regular.ttf") format("truetype"),
    url("fonts/oatmeal-pro/OatmealPro-Regular.svg#OatmealPro-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Oatmeal Pro";
  src: url("fonts/oatmeal-pro/OatmealPro-Medium.eot");
  src: url("fonts/oatmeal-pro/OatmealPro-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/oatmeal-pro/OatmealPro-Medium.woff2") format("woff2"),
    url("fonts/oatmeal-pro/OatmealPro-Medium.woff") format("woff"),
    url("fonts/oatmeal-pro/OatmealPro-Medium.ttf") format("truetype"),
    url("fonts/oatmeal-pro/OatmealPro-Medium.svg#OatmealPro-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* 1. Enable smooth scrolling */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Oatmeal Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
button,
a {
  color: var(--etpay-color-orion);
  font-weight: 400;
}

button {  
  div:nth-child(1),
  span:nth-child(1) {
    margin-right: 5px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
::before,
::after {
  box-sizing: border-box;
}

// =============================================================================
// == Layout
// =============================================================================
.layout-container {
  width: 100%;
  padding: 1rem;
  margin: auto;
  position: var(--etpay-position-relative);
}

.flex-grid {
  display: var(--etpay-display-grid);
  grid-template-columns: var(--etpay-grid-template-columns);
  column-gap: var(--etpay-grid-gap);

  @for $i from 1 through $desktop-grid-columns {
    // Columns definitions
    &__cols-#{$i} {
      grid-column: span #{$i};

      // offset definitions
      @for $j from 1 through $desktop-grid-columns {
        &.flex-grid__cols__offset-#{$j} {
          @if ($desktop-grid-columns - $i - $j + 1) >=0 {
            grid-column: #{($j + 1)} / -#{$desktop-grid-columns - $i - $j + 1};
          } @else {
            grid-column: #{($j + 1)} / -1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .flex-grid {
    @for $i from 1 through $mobile-grid-columns {
      // Columns definitions
      &__mobile__cols-#{$i} {
        grid-column: span #{$i};

        // offset definitions
        @for $j from 1 through $mobile-grid-columns {
          &.flex-grid__cols__offset-#{$j} {
            @if ($mobile-grid-columns - $i - $j + 1) >=0 {
              grid-column: #{($j + 1)} / -#{$mobile-grid-columns - $i - $j + 1};
            } @else {
              grid-column: #{($j + 1)} / -1;
            }
          }
        }

        &.flex-grid__mobile__cols__offset-none {
          grid-column: span $mobile-grid-columns;
        }
      }
    }
  }
}

// =============================================================================
// == Utils
// =============================================================================
.text-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000da 82.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  background-clip: text;
  -webkit-box-decoration-break: clone;
}

// =============================================================================
// == Responsive
// =============================================================================

// Tablet
@media screen and (max-width: 768px) {
  body {
    --etpay-rid-template-columns: #{$tablet-grid-template-columns};
    --etpay-grid-gap: #{$tablet-grid-gap};
  }
}

// Mobile
@media screen and (max-width: 576px) {
  body {
    --etpay-grid-template-columns: #{$mobile-grid-template-columns};
    --etpay-grid-gap: #{$mobile-grid-gap};
  }
}

// hide xs
@media screen and (max-width: 1200px) {
  .hide-xs {
    display: none !important;
  }
}

@media screen and (min-width: 1201px) {
  .show-xs {
    display: none !important;
  }
}
