//
// Dev styles
//
.dev-layout {
  display: var(--etpay-display-grid);
  grid-template-columns: var(--etpay-grid-template-columns);
  column-gap: var(--etpay-grid-gap);

  position: var(--etpay-position-absolute);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;

  &__col {
    grid-column: span 1;
    background: var(--etpay-color-gray);
  }
}