.why-etpay {
    @media (max-width: 1200px) {
        margin: 126px 0px 50px 0px;
    }
    min-height: 1000px;
    position: var(--etpay-position-relative);
    margin: 200px 0px;

    .title {
        @media (max-width: 1200px) {
            grid-column: 1/7;
        }

        grid-column: 3 / 11;
        margin-bottom: 300px;

        &>h1 {
            @media (max-width: 1200px) {
                font-size: var(--etpay-font-size-21);
                line-height: 114%;
            }

            font-style: normal;
            font-weight: 500;
            font-size: var(--etpay-font-size-63);
            line-height: 100%;
            text-align: center;
            letter-spacing: -0.0108em;
            color: var(--etpay-color-orion);
            margin-bottom: 48px;
        }

        &>h3 {
            @media (max-width: 1200px) {
                font-size: var(--etpay-font-size-11);
                line-height: 24px;
            }

            font-style: normal;
            font-weight: 500;
            font-size: var(--etpay-font-size-15);
            line-height: 32px;
            text-align: center;
            letter-spacing: -0.0008em;
            color: var(--etpay-color-orion);
        }
    }

    &__element-column {
        z-index: 10;
    }

    &__1-column {
        @media (max-width: 1200px) {
            grid-column: 1/7;
        }

        grid-column: 1 / 4;
    }

    &__2-column {
        @media (max-width: 1200px) {
            grid-column: 1/7;
            margin-top: 0;
        }

        grid-column: 4 / 7;
        margin-top: 70%;
    }

    &__3-column {
        @media (max-width: 1200px) {
            grid-column: 1/7;
            margin-top: 0;
        }

        grid-column: 7 / 10;
        margin-top: 50%;
    }

    &__4-column {
        @media (max-width: 1200px) {
            grid-column: 1/7;
            margin-top: 0;
        }

        grid-column: 10 / 13;
        margin-top: 15%;
    }
}