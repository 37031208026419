@import "../../vars";

.easy-integration {
    @media (max-width: 1200px) {
        grid-column: 1/7;
        order: -1;
    }

    color: var(--etpay-color-ivory);
    grid-column: 2 / 13;
    place-self: start;

    .title {
        @media (max-width: 1200px) {
            font-size: var(--etpay-font-size-20);
            line-height: 116%;
        }

        color: $color-light;
        font-family: Oatmeal Pro;
        font-size: 3.47rem;
        font-weight: 500;
        line-height: 74px;
        letter-spacing: -0.0008em;
        text-align: left;
        margin-bottom: 21px;
    }

    .tag {
        @media (max-width: 1200px) {
            font-size: var(--etpay-font-size-11);
            line-height: 24px;
            margin-bottom: 21px;
        }

        font-style: normal;
        font-weight: 500;
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
        letter-spacing: -0.0008em;
        color: var(--etpay-color-purple);
        margin-bottom: 42px;
    }

    .subtitle {
        @media (max-width: 1200px) {
            font-size: var(--etpay-font-size-11);
            line-height: 24px;
        }

        font-style: normal;
        font-weight: 400;
        font-size: var(--etpay-font-size-13);
        line-height: 34px;
        letter-spacing: -0.0008em;
        color: var(--etpay-color-ivory);
        margin-bottom: 42px;
    }
}