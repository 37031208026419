.frame-container {
    @media (max-width: 1200px) {
        width: 116px;
        height: 92px;
        border-radius: 16px;
    }
    background-color: var(--etpay-color-sirus);
    border-radius: 34px;
    width: 240px;
    height: 190px;
    display: var(--etpay-display-flex);
    justify-content: center;
    align-items: center;

    & > video{
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
    }
    & > p {
        color: var(--etpay-color-black);
        line-height: 34px;
        font-size: var(--etpay-font-size-13);
    }
}

.video-show {
    background-color: var(--etpay-color-orion);
}