.home {
  .image-banner{
    & > iframe {
      position: var(--etpay-position-absolute);
      right: -355px;
    }
  }
  .back-icon {
    @media (max-width: 1200px) {
      right: 0;
      left: -20%;
      transform: rotate(270deg);
      top: -20%;
      height: 100vh;
      display: block;
    }
    display: none;
    position: absolute;
    z-index: -1;
    right: -20%;
    top: 0;
  }

  &__easy-integration {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      margin-top: 0;
    }

    grid-column: 2/7;
  }

  &__console {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      margin-top: 52px;
    }

    grid-column: 8 / 12;
    height: fit-content;
    place-self: center;
    & div{
      color: var(--etpay-color-ivory);
    }
  }
}

.brand-list {
  grid-column: 1 / 13 !important;
}

.cardless {
  @media (max-width: 1200px) {
    font-size: 4.5625rem;
    line-height: 110px;
  }

  text-align: center;
  font-size: 10.81rem;
  line-height: 261px;
  background: linear-gradient(180deg, rgba(230, 230, 230, 0) 0%, #E6E6E6 82.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bank-types {
  grid-column: 3/11;
}

.bank-information {
  grid-column: 3/11;
}

.static-form {
  img {
    border-radius: 24px;
    box-shadow: 2px 0px 48px rgba(0, 0, 0, 0.06);
  }
}

.tabs-items {
  @media (max-width: 1200px) {
    grid-column: 1/8;
    margin-top: 70px;
  }

  margin-top: -45px;
  grid-column: 2/7;
}

// define sections margins
.section-2 {
  @media (max-width: 1200px) {
    margin-top: 50px;
  }
  margin-top: 86px;
}

.section_3 {
  @media (max-width: 1200px) {
    margin-top: 0px;
    padding-top: 1.5rem;
  }
  padding-bottom: 4em;
  background: var(--etpay-color-sirus);

  // &__subsection_1 {
  //   // margin-top: 86px;
  // }

  &__subsection_2 {
    @media (max-width: 1200px) {
      margin-top: 0px;
      height: 100px;
      .cardless{
        transform: none !important;
      }
    }
    margin-top: 86px;
    position: relative;
    height: 200px;
  }

  &__subsection_3 {
    @media (max-width: 1200px) {
      margin-top: 0;
    }

    margin-top: 200px;
    z-index: 1;
  }

  &__subsection_4 {
    @media (max-width: 1200px) {
      margin-top: 20px;
    }
    margin-top: 86px;

    .inline-form {
      width: 100%;
      height: 586px;
    }
  }

  &__subsection_5 {
    @media (max-width: 1200px) {
      margin-top: 20px;
    }
    margin-top: 86px;
  }

  &__subsection_6 {
    @media (max-width: 1200px) {
      margin-top: 20px;
    }
    margin-top: 86px;
  }
}

.promotion{
  width: 100%;
  margin-top: 48px;
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-row);

  & > img {
    margin-right: 6px;
    width: 46px;
    height: 46px;
  }
  & > p {
    margin-top: 0px;
    font-weight: 500;
  }
}