@import "../../vars";

.special-section {

    position: $position-relative;
    min-height: 1200px;
    transition: all .5s ease-in-out;
    display: var(--etpay-display-flex);
    align-items: center;
    background-image: url("../../assets/backgroundSpecialSection.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    & div {
        z-index: 1;
    }

    .column {
        @media (max-width: 1200px) {
            padding-top: 138px;
            padding-bottom: 117px;
        }

        padding-top: 330px;
        padding-bottom: 245px;
    }
}