.console {
  @media (max-width: 1200px) {
    width: 100%;
    font-size: .6875rem;
    line-height: 20px
  }

  font-family: monospace;
  width: 555px;
  border: 1px solid var(--etpay-color-purple);
  border-radius: 24px;
  overflow: hidden;
  font-size: var(--etpay-font-size-11);
  color: white;

  .color1 {
    color: var(--etpay-color-purple);
  }

  .color2 {
    color: var(--etpay-color-blue);
  }

  .color3 {
    color: #ff7576;
  }

  .color4 {
    color: #4c4542;
  }

  .color5 {
    color: #ffd96a;
  }

  .color6 {
    color: #55718d;
  }

  .top {
    display: var(--etpay-display-flex);
    height: 277px;
    background-color: var(--etpay-color-half-black);
    padding: 18px 0 18px 24px;

    .line {
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      padding-right: 24px;
      user-select: none;

      .line-number {
        @media (max-width: 1200px) {
          margin: 3px 0;
        }

        margin: 7px 0;
      }
    }

    .code {
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      overflow: hidden;

      .line-content {
        display: var(--etpay-display-flex);
      }

      &__line {
        @media (max-width: 1200px) {
          height: 20px;
          margin: 3px 0;
        }

        display: inline-block;
        margin: 7px 0;
        height: 22px;
        min-width: 0;
        overflow: hidden;
        min-width: fit-content;
        white-space: nowrap;
        color: var(--etpay-color-pure);

        span {
          display: inline-block;
        }

        &.tab-1 {
          padding-left: 50px;
        }

        &.cursor {
          border-right: 2px solid var(--etpay-color-less-brown);
        }

        &.cursor-end {
          position: var(--etpay-position-relative);

          &::after {
            content: '';
            background-color: var(--etpay-color-less-brown);
            border-radius: 3px;
            position: var(--etpay-position-absolute);
            width: 13px;
            height: 24px;
            right: 0;
            top: 0;
            transform: translateX(100%);
          }
        }
      }
    }
  }

  .bottom {
    height: 277px;
    background-color: var(--etpay-color-orion);

    .bar {
      display: var(--etpay-display-flex);
      justify-content: space-between;
      background: var(--etpay-color-secondary);
      user-select: none;

      &.active {
        .left {
          .status {
            background-color: #ffd96a;
          }

          .archive {
            color: #ffd96a;
          }
        }
      }

      .left {
        display: var(--etpay-display-flex);
        align-items: center;
        column-gap: 10px;

        .status {
          background: var(--etpay-color-purple);
          color: var(--etpay-color-secondary);
          text-transform: uppercase;
          padding: 0 14px;
          clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0 100%, 0 0);
        }

        .archive {
          color: var(--etpay-color-purple);
        }
      }

      .right {
        display: var(--etpay-display-flex);
        align-items: center;
        padding-right: 19px;
        color: var(--etpay-color-vanth);
      }
    }

    .shell {
      padding: 24px 0 24px 24px;

      .line-shell-content {
        display: var(--etpay-display-flex);

        .shell__line {
          margin: 3px 0;
          height: 22px;
          overflow: hidden;
          white-space: nowrap;
          color: var(--etpay-color-pure);

          span {
            display: inline-block;
          }

          &.cursor {
            padding-right: 2px;
            border-right: 13px solid white;
          }
        }
      }
    }
  }
}

.line-number {
  color: var(--etpay-color-vanth);
}