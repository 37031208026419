@import "../../vars";

.options-container {
    @media (max-width: 1200px) {
        grid-column: 1/7;
    }

    color: var(--etpay-color-ivory);
    grid-column: 2 / 6;
    display: $display-grid;
    grid-template-columns: 1fr;

    .option {
        border-radius: 24px;
        color: $color-light;
        display: $display-grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 20px;
        padding: 20px;
        margin: 12px 0px;
        cursor: pointer;

        .number {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            grid-column: 1 / 2;
            place-self: flex-start;
            display: $display-flex;
            justify-content: center;
            align-items: center;
            position: $position-relative;
            background: rgba(0, 0, 0, 0);
            color: var(--etpay-color-sirus);

            &__active {
                background-color: var(--etpay-color-purple);
                color: var(--etpay-color-orion);
            }

            & svg {
                position: $position-absolute;
                width: 50px;
                height: 50px;
            }
        }

        .text {
            grid-column: 2 / 12;
            font-size: var(--etpay-font-size-15);
            line-height: 32px;
            color: var(--etpay-color-sirus);
            font-weight: 500;

            
        }
        @media(max-width:1200px) {
            .text {
                font-size: 18px !important;
            }
        }
    }

    .active {
        background: var(--etpay-color-orion);
        transition: all .4s linear;
    }
}

.border {
    border: 1px solid var(--etpay-color-purple);
}