$font-oatmeal: 'Oatmeal Pro';
$font-oatmeal-regular: 'Oatmeal Pro Regular';
$display-flex: flex;
$flex-direction-column: column;
$flex-direction-row: row;
$center-element: center;
$display-grid: grid;
$grid-columns-12: repeat(12, 1fr);
$grid-columns-6: repeat(6, 1fr);
$grid-gap-8: 8px;
$grid-gap-32: 32px;
$position-relative: relative;
$position-absolute: absolute;

//colors
$color-secondary: #262255;
$color-light: #FFFFFF;
$text-color: #140F33;
$color-canes: #5c2a48;
$color-sirus: #FCFCFC;
$color-vanth: #646174;
$color-moon: #C1C4C4;
$color-purple: #857BF9;
$color-border: #F2F2F2;
$color-gray: #f4dddd57;
$color-half-black: #0F0F0F;
$color-less-brown: #716661;
$color-blue: #426dd8;
$color-ivory: #E6E6E6;
$color-button: #EFEFEF;

// =============================================================================
// == Desktop vars
// =============================================================================
$desktop-grid-template-columns: repeat(12, 1fr);
$desktop-grid-gap: 32px;
$desktop-grid-columns: 12;

// =============================================================================
// == Tablet vars
// =============================================================================
$tablet-grid-template-columns: repeat(12, 1fr);
$tablet-grid-gap: 24px;
$tablet-grid-columns: 12;

// =============================================================================
// == Mobile vars
// =============================================================================
$mobile-grid-template-columns: repeat(6, 1fr);
$mobile-grid-gap: 8px;
$mobile-grid-columns: 6;

$scale-1: scaleX(1);

// =============================================================================
// == Font Size REM
// =============================================================================
$font-size-07: .75rem;
$font-size-08: .875rem;
$font-size-10: 1rem;
$font-size-11: 1.125rem;
$font-size-12: 1.25rem;
$font-size-13: 1.375rem;
$font-size-13-1: 1.3125rem;
$font-size-15: 1.3125rem;
$font-size-20: 2rem;
$font-size-21: 2.125rem;
$font-size-23: 2.375rem;
$font-size-26: 2.625rem;
$font-size-40: 4rem;
$font-size-63: 5.375rem;