.header-page {
  @media (max-width: 1200px) {
    // background-color: rgba(255, 255, 255, 0);
  }
  width: 100%;
  position: sticky;
  top: 0; //var(--etpay-position-relative);
  z-index: 100;
  margin: auto;
  display: var(--etpay-display-flex);
  justify-content: center;
  background-color: rgba(255, 255, 255, .95);
  backdrop-filter: blur(7px);

  &-container {
    @media (max-width: 1200px) {
      padding: 0;
    }

    width: 100%;
    max-width: 1376px;
    padding: 0 32px;
  }

  .mobileMenu {
    @media (max-width: 1200px) {
      background-color: rgba(255, 255, 255, 0);
    }
    background-color: rgba(255, 255, 255, .95);
    position: var(--etpay-position-absolute);
    top: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transform: scale(.95);
    transform-origin: 100% 0;
    transition: .2s;

    &.open {
      pointer-events: auto;
      opacity: 1;
      transform: scale(1);
      background-color: var(--etpay-color-pure);
    }
  }

  .mobile-title {
    @media (max-width: 1200px) {
      backdrop-filter: none;
      border-bottom: none;
      padding-top: 15px;
    }
    backdrop-filter: blur(34px);
    border-bottom: 2px solid var(--etpay-color-border);
    display: var(--etpay-display-flex);
    justify-content: space-between;
    align-items: flex-end;
    padding: 61px 15px 17px;

    &.developer-page {
      background-color: var(--etpay-color-orion);
      color: var(--etpay-color-pure);
      border-bottom: none;

      .logo div:nth-of-type(2) svg path {
        fill: var(--etpay-color-pure);
      }

      & .menuToggle {
        background: var(--etpay-color-vanth);

        &::before,
        &::after {
          background-color: var(--etpay-color-sirus);
        }
      }
    }

    .logo {
      display: var(--etpay-display-flex);
      align-items: center;
      width: 100%;
    }

    .etpayLogo {
      margin-right: 7px;
    }

    .menuToggle {
      border-radius: 12px;
      background: var(--etpay-color-sirus);
      width: 42px;
      height: 42px;
      margin-left: auto;
      display: var(--etpay-display-flex);
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &::before {
        content: '';
        position: var(--etpay-position-absolute);
        width: 24px;
        height: 2px;
        background: var(--etpay-color-orion);
        transform: translateY(-5px);
        transition: 0.5s;
      }

      &::after {
        content: '';
        position: var(--etpay-position-absolute);
        width: 24px;
        height: 2px;
        background: var(--etpay-color-orion);
        transform: translateY(5px);
        transition: 0.5s;
      }

      &.open::before {
        transform: translateY(0px) rotate(45deg);
      }

      &.open::after {
        transform: translateY(0px) rotate(-45deg);
      }
    }
  }

  a {
    text-decoration: none;
    display: var(--etpay-display-flex);
    //height: 34px;
  }

  .products-services {
    padding: 43px 24px 0 24px;

    span {
      font-weight: 400;
      font-size: var(--etpay-font-size-08);
      color: var(--etpay-color-vanth);
      line-height: 120%;
      min-width: max-content;
    }


    .services {
      margin: 0 !important;
      border-bottom: 2px solid var(--etpay-color-border);
      width: 100%;
    }
  }

  .productMenuContainer {
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    gap: 18px;
    margin: 15px 0 51px;

    span {
      font-weight: 400;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }
  }

  .servicesContainer {

    // width: 246px;
    // height: 86px;
    @media (max-width: 1200px) {
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      gap: 18px;
      padding-top: 18px;
    }

    span {
      font-weight: 400;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }
  }

  .resources {
    padding: 30px 0 43px 0;
    margin: auto;
    border-bottom: 2px solid var(--etpay-color-border);
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: center;

    a {
      height: 24px;
      //color: var(--etpay-color-orion);
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
      letter-spacing: 0.003em;
    }
  }

  .resourcesContainer {
    //width: 137px;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: center;
    gap: 21px;
    padding-top: 18px;

    a {
      color: var(--etpay-color-orion);
    }
  }

  .signIn {
    height: 125px;
    display: var(--etpay-display-flex);
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 24px 43px;

    .btn-container {
      height: 52px;
      width: 316px;
      display: var(--etpay-display-flex);
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
      margin-right: auto;

    }
    & a:nth-child(2){
      & button{
        background-color: var(--etpay-color-button);
        &:hover{
          background-color: var(--etpay-color-orion);
        }
      }
    }
  }

  .text-overflow {
    max-width: 111px;
  }

  .desktopMenu {
    display: none;
  }
}

.divider {
  width: 100%;
  height: 2px;
  background-color: var(--etpay-color-border);
}


@media (min-width: 600px) {
  .header-page {
    padding: 30px 32px;

    .mobileMenu,
    .mobile-title {
      display: none;
    }

    &.developer-page {
      background-color: var(--etpay-color-orion);

      span {
        color: var(--etpay-color-pure);
      }

      button {
        background-color: var(--etpay-color-orion);
      }

      .logo div:nth-of-type(2) svg path {
        fill: var(--etpay-color-pure);
      }

      .submenu span:not(.subtitle) {
        color: unset;
      }

      .submenu span.title {
        margin-bottom: 12px;
        font-size: var(--etpay-font-size-11);
        line-height: 27px;
        color: var(--etpay-color-vanth);
      }

      .itemList>div svg path {
        stroke: var(--etpay-color-pure);
      }

      .signIn a:nth-child(2) button {
        background-color: var(--etpay-color-vanth);
        &:hover{
          background-color: var(--etpay-color-orion);
        }
      }
    }

    .desktopMenu {
      align-items: center;
      display: var(--etpay-display-flex);
      justify-content: space-between;
    }

    nav {
      grid-column: 4 / 9;
      max-width: 640px;
      height: 52px;
      display: var(--etpay-display-flex);
      gap: 3px;
      justify-content: space-between;
      justify-self: center;
      align-items: baseline;

      a,
      .itemList {
        font-weight: 400;
        font-size: var(--etpay-font-size-10);
        line-height: 20px;
        color: var(--etpay-color-orion);
        flex-shrink: 0;
        cursor: pointer;
        padding: 0;
      }

      .itemList {
        padding: 14px 16px 24px 18px;
        display: var(--etpay-display-flex);
        align-items: center;
        position: var(--etpay-position-relative);

        span {
          margin: 0;
          margin-right: 8px;
        }

        span+div {
          transition: transform 0.2s ease;
        }

        span+div>div {
          display: var(--etpay-display-flex);
          align-items: center;
        }

        span+div.open {
          transform: rotate(180deg);
        }
      }
    }

    .submenu {
      width: 100%;
      height: fit-content;
      position: var(--etpay-position-absolute);
      background: var(--etpay-color-sirus);
      border-top: 1px solid var(--etpay-color-ivory);
      top: 100%;
      backdrop-filter: blur(12px);
      border-radius: 0 0 12px 12px;
      pointer-events: none;
      padding: 32px 149px 69px 149PX;
      opacity: 0;
      left: 0;
      transition: all 0.3s;
      display: var(--etpay-display-flex);
      justify-content: center;
      gap: 97px;

      &-container {
        width: 100%;
        max-width: 1376px;
        display: var(--etpay-display-flex);
      }

      span {
        font-weight: 400;
        font-size: var(--etpay-font-size-12);
        letter-spacing: -0.0008em;
        margin-top: 0;
        white-space: nowrap;
      }

      span.title {
        margin-bottom: 12px;
        font-size: var(--etpay-font-size-11);
        line-height: 27px;
        color: var(--etpay-color-vanth);
      }

      //.productsContainer {
      //  grid-column: 2 / 5;
      //}
      //
      //.servicesContainer {
      //  grid-column: 5 / 8;
      //}

      .products {
        width: 374px;
        display: var(--etpay-display-flex);
        flex-direction: var(--etpay-flex-direction-column);
        gap: 24px;
        margin: 20px 0 0 0;

        a {
          height: 60px;
        }
      }

      .services {
        width: 285px;
        display: var(--etpay-display-flex);
        flex-direction: var(--etpay-flex-direction-column);
        gap: 24px;
        grid-column: 6 / 8;
        margin: 20px 0 0 0;

        a {
          height: 60px;
        }
      }

      .subtitle {
        font-size: var(--etpay-font-size-12);
        letter-spacing: -0.0008em;
        color: var(--etpay-color-vanth);
        margin: .3rem 0 0 0;
      }
    }

    .submenu.open {
      opacity: 1;
      pointer-events: auto;
    }

    .logo {
      display: var(--etpay-display-flex);
      gap: 8px;
      grid-column: 1 / 3;
    }

    .signIn {
      padding: 0;
      height: 52px;
      display: var(--etpay-display-flex);
      gap: 3px;
      grid-column: 9 / 13;
      justify-self: end;
    }
  }
}

.d-flex {
  display: var(--etpay-display-flex);
  align-items: center;
  gap: 8px;
}

.menu-fixed {
  // position: fixed;
  // width: 100%;
  // background-color: rgba(255, 255, 255, 0.95);
}