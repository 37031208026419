.terms-and-conditions-page {
  @media (max-width: 1200px) {
    overflow-x: hidden;
  }
  position: relative;
  padding-bottom: 532px;  
  //padding-bottom: 97px;

  //@media (min-width: 1024px) {
  //  padding-bottom: 532px;
  //}

  .banner-section {
    padding-top: 77px;
    padding-bottom: 157px;
  }
}