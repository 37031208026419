footer {
  padding-top: 56px;
  padding-bottom: 23px;
}

.footer-page {
  //display: var(--etpay-display-grid);
  //grid-template-columns: repeat(6, 1fr);
  // grid-template-rows: auto auto auto auto auto 180px;
  //gap: 8px;
  // padding: 62px 0 56px;
  color: var(--etpay-color-orion);
  background: var(--etpay-color-sirus);
  // border: 2px solid red;
  // background: red;

  .layout-container {
    position: relative;
  }

  &.developer-page {
    color: var(--etpay-color-sirus);
    background: var(--etpay-color-orion);

    & h3, p, a{
      color: var(--etpay-color-sirus);
    }

    .products ul li .commingSoon {
      background: var(--etpay-color-secondary);
    }

    .divider {
      border-bottom: 1px solid var(--etpay-color-secondary);
      background-color: transparent;
    }

    .etpayLogo svg path {
      fill: var(--etpay-color-purple);
    }

    .icons svg path {
      fill: #fcfcfc;
    }
  }

  p,
  li,
  a {
    color: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  h3 {
    margin: 0;
  }
}

.menu-link {
  h3 {
    margin-bottom: 12px;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
  }

  ul {
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: flex-start;
    gap: 21px;

    li {
      font-weight: 400;
      font-size: var(--etpay-font-size-11);
      line-height: 20px;
      display: var(--etpay-display-flex);
      align-items: center;

      .commingSoon {
        font-size: var(--etpay-font-size-07);
        line-height: 12px;
        background: var(--etpay-color-orion);
        border-radius: 4px;
        margin-left: 8px;
        color: var(--etpay-color-sirus);
        padding: 6px;
      }
    }
  }
}

.locate {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;

  .etpayLogo {
    margin-bottom: 20px;

    svg {
      path {
        fill: var(--etpay-color-ivory);
      }
    }
  }

  p {
    max-width: 269px;
    margin-bottom: 39px;
  }

  .icons {
    display: var(--etpay-display-flex);
  }
}

.divider {
  border-bottom: 1px solid var(--etpay-color-border);
  margin-top: 16px;
  margin-bottom: 16px;
}

.terms {
  p {
    margin: 0;
  }
}

.links {
  padding: 0px;
  gap: 14px;
  width: 193px;
  height: 88px;
  align-self: center;
}

@media (min-width: 1024px) {
  footer {
    // padding: 0;
  }
}

@media (max-width: 768px) {
  .menu-link {
    margin-bottom: 71px;
    margin-left: 80px;

    // align-items: center;
    // justify-content: center;
    // display: flex;

  }

  footer {

    .footer-content {
      display: flex;
      flex-direction: column-reverse;
    }

    .copyright {
      text-align: right;

      .flex-grid>div {

        margin-bottom: 24px;
      }

      a {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}