.people {
    &__office {
        &-video_frame {
            @media (max-width: 1200px) {
                gap: 10px;
            }

            display: var(--etpay-display-grid);
            // -ms-grid-columns: 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr 20px 1fr;
            // grid-template-columns: repeat(12, 1fr);
            gap: 20px;
            height: 100%;
            width: 100%;

            & div:nth-child(1),
            div:nth-child(7),
            div:nth-child(13) {
                -ms-grid-column: 1;
                -ms-grid-column-span: 2;
                grid-column: 1 / 3;
            }

            & div:nth-child(2),
            div:nth-child(8),
            div:nth-child(14) {
                -ms-grid-column: 3;
                -ms-grid-column-span: 2;
                grid-column: 3 / 5;
            }

            & div:nth-child(3),
            div:nth-child(9),
            div:nth-child(15) {
                -ms-grid-column: 5;
                -ms-grid-column-span: 2;
                grid-column: 5 / 7;
            }

            & div:nth-child(4),
            div:nth-child(10),
            div:nth-child(16) {
                -ms-grid-column: 7;
                -ms-grid-column-span: 2;
                grid-column: 7 / 9;
            }

            & div:nth-child(5),
            div:nth-child(11),
            div:nth-child(17) {
                -ms-grid-column: 9;
                -ms-grid-column-span: 2;
                grid-column: 9 / 11;
            }

            & div:nth-child(6),
            div:nth-child(12),
            div:nth-child(18) {
                -ms-grid-column: 11;
                -ms-grid-column-span: 2;
                grid-column: 11 / 13;
            }
        }
    }
}

.people-team-photo {
    width: 915px;
    height: 611px;

    & img {
        width: 100%;
    }
}

.desktop-mode {
    @media (max-width: 1200px) {
        display: none;
    }
}

.mobile-mode {
    @media (min-width: 1200px) {
        display: none;
    }

    & img {
        width: 100%;
    }

    &>div:nth-child(1) {
        margin-bottom: 20px;
    }
}