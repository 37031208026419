.safe-method {
  @media (max-width: 1200px) {
    padding-bottom: 50px;
  }

  padding-top: 160px;
  padding-bottom: 200px;

  .title {
    @media (max-width: 1200px) {
      grid-column: 1 / 7;
      font-size: var(--etpay-font-size-20);
      line-height: 116%;
    }

    grid-column: 3/11;
    font-weight: 500;
    font-size: var(--etpay-font-size-40);
    line-height: 116%;
  }

  .description {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 3/11;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
    padding: 24px 0 68px 0;
  }

  .gap {
    @media (max-width: 1200px) {
      display: none;
    }

    grid-row: 3;
    grid-column: span 2;
  }

  .item {
    @media (max-width: 1200px) {
      grid-row: auto;
      padding-bottom: 68px;
    }

    grid-row: 3;
    grid-column: span 3;
    border-top: 1px solid var(--etpay-color-border);
    padding-top: 16px;

    &__title {
      @media (max-width: 1200px) {
        font-size: 1.625rem;
        line-height: 110%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 110%;
      width: 70%;

      & div {
        display: var(--etpay-display-flex);
      }
    }

    &__description {
      color: var(--etpay-color-vanth);
      padding-top: 8px;
    }
  }
}