.select-root {
  @media (max-width: 1200px) {
    margin-top: 1.25rem;
  }
  font-size: var(--etpay-font-size-11);
  line-height: 20px;
  height: 52px;
  display: var(--etpay-display-flex);
  align-items: center;
  //padding: 0 24px;
  gap: 8px;
  border-radius: 12px;
  background-color: var(--etpay-color-pure)FFF;
  cursor: pointer !important;
  transition: all 0.2s ease;
  display: var(--etpay-display-flex);
  align-items: center;
  position: var(--etpay-position-relative);
  padding: 0px 5px;

  &:hover {

    &.native,
    .default {
      background-color: var(--etpay-color-orion);
    }

    select {
      color: white;
    }

    .icon {
      filter: brightness(100);
    }
  }

  select {
    font-family: 'Oatmeal Pro', sans-serif;
    border-radius: inherit;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    height: 100%;

    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;    
  }

  button {
    background-color: var(--etpay-color-button);
  }
}

.select-options {  
  background: var(--etpay-color-pure);
  position: var(--etpay-position-absolute);
  display: none;
  left: calc(50%);
  top: 50px;
  transition: all 0.2s ease;
  border-radius: 12px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  min-width: 128px;

  &.opened {
    display: block;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: center;
    justify-content: center;
    width: 100%;

    li {
      padding: 24px;
      width: 100%;

      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-row);
      align-items: center;
      justify-content: flex-start;
      min-height: 64px;

      &:hover,
      &.selected {
        background-color: var(--etpay-color-orion);
        color: var(--etpay-color-pure);
      }
    }
  }
}