.bubble {
  background-color: #E9E9EB;
  font-size: var(--etpay-font-size-20);
  line-height: 125%;
  border-radius: 43.9437px;
  padding: 26px;
  position: var(--etpay-position-relative);
  transition: all .4s cubic-bezier(.07, 1.27, .27, 1.08);
  transform: rotate(-10deg) translate(-10px, 50px) scale(0);
  transform-origin: 0 100%;
  opacity: 0;

  &.active {
    transform: rotate(0deg) translate(0, 0) scale(1);
    opacity: 1;
  }

  .tip {
    position: var(--etpay-position-absolute);
    bottom: 0;
    left: 0;
    transform: translate(-20%, 0%);
    z-index: -1;
  }

  & a {
    color: #1657FF;
  }
}