  .list-component {
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      grid-template-rows: auto;
    }

    display: var(--etpay-display-grid);
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, 1fr);
    grid-template-rows: repeat(8, 1fr);
    column-gap: 1rem;
  }