.whatAppClientContainer {
  @media (max-width: 1200px) {
    // padding: 50px 24px;
    margin: 0px auto 75px;
  }

  // padding: 170px 24px;
  margin: 65px auto 75px;

  h3 {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 2 / 13;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
    letter-spacing: 0.003em;
    color: var(--etpay-color-orion);
    margin: 0
  }

  p {
    @media (max-width: 1200px) {
      grid-column: 1 / 7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 2 / 6;
    font-size: var(--etpay-font-size-13);
    line-height: 34px;
    letter-spacing: 0.003em;
    color: var(--etpay-color-orion);
  }

  .list {
    @media (max-width: 1200px) {
      grid-column: 1/7;
    }

    grid-column: 2 / 7;
    grid-row-start: 3;
    margin-top: 40px;

    p {
      font-size: var(--etpay-font-size-10);
      line-height: 24px;
      height: 24px;
    }
  }

  .leyend {
    grid-column: 2 / 7;
    grid-row-start: 4;
    padding-top: 80px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #646174;
    }
  }

}

@media (max-width:1200px) {
  .whatAppClientContainer .leyend {
    grid-column: 1/7 !important;
  }
}