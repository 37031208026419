.quick-transfer {
    .special-section {
        .column {
            padding-top: 165px;
            padding-bottom: 211px;
        }
    }
}
@media (max-width:1200px){
    .show-desktop{
        display: none !important;
    }
    .show-mobile{
        display: block !important;
    }
}
.show-desktop{
    display: block;
}
.show-mobile{
    display: none;
}