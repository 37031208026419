.use-cases {
  .back-icon {
    position: absolute;
    z-index: -1;
    top: -5%;
    right: -10%;
    width: 70vw;
    height: 120%;
  }
  .banner {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;


    .grid-6{
      @media (max-width: 1200px) {
        margin-top: 32px;       
      }

      .title h3{
        width: 90% !important;
      }

      .fillable{
        & path{
          fill: var(--etpay-color-sirus);
        }
      }
    }
  }
}

.banner-useCases{
  align-items: center;
}

.banner-container--max{
  padding: 0;
}