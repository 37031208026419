.convertion {
  @media (max-width: 1200px) {
    margin-top: 65px;
    margin-bottom: 106px;
  }
  .right {
    @media (max-width: 1200px) {
      grid-column: 1/7;
    }

    grid-column: 2/7;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    justify-content: flex-end;

    .top-title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      font-weight: 500;
    }

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-20);
        line-height: 116%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 114%;
      padding: 34px 0;
    }

    .description {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }
  }

  .left {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      margin-top: 80px;
    }

    grid-column: 8/13;
    display: var(--etpay-display-flex);
    justify-content: center;
    align-items: center;
  }
}