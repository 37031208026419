@import "../../vars";

.banner-container--max {
    @media (max-width: 1200px) {
        height: auto !important;
        padding-top: 0px;
        padding-bottom: 0px;
        overflow: hidden;
    }

    width: 100vw;
    display: var(--etpay-display-flex);
    justify-content: center;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 65px;
    padding-bottom: 65px;
    position: relative;

    .banner-back-mesh {
        @media (max-width: 1200px) {
            top: -5%;
            left: 235%;
            height: 150vh;
        }

        position: var(--etpay-position-absolute);
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        bottom: 0;
        display: var(--etpay-display-flex);
        justify-content: flex-end;
        z-index: -1;

        &-img {
            height: 100%;
        }
    }
}

.banner-container {
    @media (max-width: 1200px) {
        padding-bottom: 20px;
        padding: 0px 15px;
    }

    align-items: flex-start;
    display: $display-grid;
    grid-template-columns: var(--etpay-grid-template-columns);
    grid-gap: var(--etpay-grid-gap);
    padding: 0px 0px 0px 20px;
    margin-bottom: 50px;
    background-position: right;
    background-repeat: no-repeat;
    background-size: auto;
    max-width: 1376px;

    .content {
        @media (max-width: 1200px) {
            grid-column: 1 / 7;
        }

        grid-column: 1 / 8;
        // display: flex;
        flex-direction: column;
        justify-content: center;
        position: var(--etpay-position-relative);
        z-index: 10;
        display: flex;

        .title {
            &>h1 {
                @media (max-width: 1200px) {
                    font-size: var(--etpay-font-size-21);
                    line-height: 114%;
                    margin-top: 30px;
                }

                font-style: normal;
                font-weight: 500;
                font-size: var(--etpay-font-size-63);
                line-height: 100%;
                letter-spacing: -0.0108em;
                margin: 80px 0px 34px 0px;
            }

            &>h3 {
                @media (max-width: 1200px) {
                    font-size: var(--etpay-font-size-12);
                    line-height: 26px;
                    white-space: normal;
                }

                font-style: normal;
                font-weight: 500;
                font-size: var(--etpay-font-size-15);
                line-height: 32px;
                letter-spacing: -0.0008em;
                width: 100%;
                margin-bottom: 60px;
                white-space: pre-line;
            }
        }

        .footer {
            @media (max-width: 1200px) {
                flex-direction: var(--etpay-flex-direction-column);
                align-items: start;
                row-gap: 12px;
            }

            display: $display-flex;
            flex-direction: $flex-direction-row;
            align-items: $center-element;
            flex-wrap: wrap;

            & button {
                @media (max-width: 1200px) {
                    margin: 0;
                }

                margin-right: 10px;
            }
        }
    }

    .image-banner {
        @media (max-width: 1200px) {
            grid-column: 1 / 7 !important;
            margin-top: 36px !important;

            &>img {
                width: 100% !important;
                float: none;
            }
        }

        grid-column: 9 / 13;
        height: auto;
        width: 125%;

        &>img {
            width: 100%;
            float: right;
            height: 100%;
            object-fit: contain;
        }
    }

    .banner-top-icon {
        @media (max-width: 1200px) {
            grid-column: 1/7;
        }

        grid-column: 1 / 13;
        place-self: start;
        display: $display-flex;
        flex-direction: $flex-direction-row;
        align-items: $center-element;
        padding-bottom: 2rem;

        &>div {
            padding: 7px;
            border-radius: 12px;
            background-color: var(--etpay-color-border);
        }

        &>p {
            font-style: normal;
            font-weight: 500;
            font-size: var(--etpay-font-size-15);
            line-height: 32px;
            letter-spacing: -0.0008em;
            margin: 0px 10px;
        }
    }
}

//Updates for quick page
.banner-quick {
    @media (max-width: 1200px) {
        grid-template-columns: repeat(6, 1fr);
        padding: 4rem 1.25rem 0px 1.25rem;
    }

    padding: 4rem 1.25rem 0px 1.25rem;
}

.grid-6 {
    @media (max-width: 1200px) {
        grid-column: 1/7 !important;
    }

    grid-column: 1 / 7 !important;

    .title {
        &>h1 {
            @media (max-width: 1200px) {
                font-size: var(--etpay-font-size-21) !important;
            }

            font-size: var(--etpay-font-size-40) !important;
            margin: 0px 0px 34px 0px !important;
        }

        &>h3 {
            width: 65% !important;
        }

        @media (max-width:1200px){
            h3{
                width: 100% !important;
            }
        }
    }
}

.grid-5 {
    @media (max-width: 1200px) {
        grid-column: 1/7 !important;
    }

    grid-column: 1 / 6 !important;

    .title {
        &>h1 {
            @media (max-width: 1200px) {
                font-size: var(--etpay-font-size-21) !important;
            }

            font-size: var(--etpay-font-size-40) !important;
            margin: 0px 0px 34px 0px !important;
            width: 100% !important;
        }

        &>h3 {
            @media (max-width: 1200px) {
                margin-bottom: 50px !important;
            }

            width: 95% !important;
        }
    }
}