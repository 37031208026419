@import '../../vars';


.tarifas {
  @media (max-width: 1200px) {
    padding-bottom: 0px;
  }
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  padding: 125px 0;
  position: relative;
  overflow: hidden;

  .back {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--etpay-color-sirus);
    z-index: -1;

    .back-icon {
      position: absolute;
      right: -30%;
      top: 0;
      height: 110%;
    }
  }

  .top-title {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 2 / 13;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
  }

  .title {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11)!important;
      line-height: 116%;
    }

    grid-column: 2 / 13;
    font-weight: 500;
    font-size: var(--etpay-font-size-40);
    line-height: 116%;
    padding: 21px 0;
  }

  .sub-title {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 2 / 7;
    font-size: var(--etpay-font-size-13);
    line-height: 34px;
    padding-bottom: 100px;
  }

  .tab-content {
    @media screen and (max-width: 1200px) {
      grid-column: 1 / 7;
    }

    grid-column: 2 / 12;

    .value {
      & div{
        font-weight: 500;
      }
    }
  }

  .content {
    .column {
      .title {
        grid-column: 2 / 4;
        font-size: var(--etpay-font-size-15);
        padding: 0px;
      }
      .text {
        @media screen and (max-width: 1200px) {
          grid-column: 1 / 5;
          margin-bottom: 7rem;
        }
        grid-column: 5 / 9;
        margin-bottom: 10rem;
      }
      .value{
        @media screen and (max-width: 1200px) {
          grid-column: 5 / 7;
        }
        grid-column: 10 / 12;
        text-align: right;
        .top{
          font-weight: 500;
          font-size: var(--etpay-font-size-23);
        }
        @media (max-width:1200px) {
          .top{
            font-size: var(--etpay-font-size-15) !important;
          }
        }
        .bottom{
          font-size: var(--etpay-font-size-15);
        }
      }
      .end{
        display: flex;
        justify-content: flex-end;
        align-items: baseline;

        a{
          text-align: center;
          background-color: rgb(239, 239, 239);
          padding: 1rem 0rem;
          width: 100%;
          border-radius: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: .2rem;
        }

        @media (max-width:1200px){
          a{
            background-color: rgb(239, 239, 239);
            padding: 1rem 1rem;
            border-radius: 12px;
          }
        }
      }

    }
  }

}