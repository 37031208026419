@import '../../vars';

.slider {
  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--etpay-color-sirus);
  &>*{
    @media (max-width: 1200px) {
      margin-bottom: 50px;
    }
    margin-bottom: 100px;
  }

  .top {
    .left {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }
      
      grid-column: 1 / 6;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      justify-content: space-between;
      row-gap: 35px;

      .service {
        display: var(--etpay-display-flex);
        column-gap: 10px;
        align-items: center;

        .icon {
          background: var(--etpay-color-border);
          border-radius: 6.92763px;
          width: 49.07px;
          height: 49.07px;
          display: var(--etpay-display-flex);
          justify-content: center;
          align-items: center;
        }

        .service-name {
          font-weight: 500;
          font-size: var(--etpay-font-size-15);
          color: #140F33;
        }
      }

      .title {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-21);
          line-height: 114%;
          padding-bottom: 24px;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-23);
        line-height: 124%;

        &.no-icon {
          @media (max-width: 1200px) {
            font-size: var(--etpay-font-size-21);
          }

          font-size: var(--etpay-font-size-63);
        }
      }
    }

    .right {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 6 / 10;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      justify-content: center;

      .description {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;        
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
        margin-bottom: 31px;
      }
    }
  }

  .bottom {
    display: var(--etpay-display-flex);
    justify-content: flex-start;
    padding-bottom: $grid-gap-32;
    overflow-x: auto;
    width: 100%;
    max-width: 1376px;

    &::-webkit-scrollbar {
      height: 4px;
      width: 100%;
    }

    &::-webkit-scrollbar-track {
      background: var(--etpay-color-ivory);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--etpay-color-orion);
      border-radius: 12px;
    }
  }
}