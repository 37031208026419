.gif {

  // hidde on 1200px
  @media (max-width: 1200px) {
    display: none;
  }

  position: relative;

  &__img {
    max-width: 100%;
    max-height: calc(100vh - 200px);
    opacity: 0;
    transition: .3s;

    &.active {
      opacity: 1;
    }

    &.absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}