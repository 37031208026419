.tips {
  width: 100% !important;
background-color: var(--etpay-color-sirus);
  .text {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    margin-top: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid var(--etpay-color-ivory);
    grid-column: span 4;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
    color: var(--etpay-color-orion);

    & b {
      font-weight: 500;
    }
  }
}