.posible-width-etpay {
  @media (max-width: 1200px) {
    padding-top: 75px;
    padding-bottom: 73px;
  }
  color: var(--etpay-color-orion);
  background-color: var(--etpay-color-sirus);
  padding-top: 125px;
  padding-bottom: 170px;

  .title {
    @media (max-width: 1200px) {
      grid-column: 1 / 13;
      font-size: var(--etpay-font-size-20);
      line-height: 116%;
      padding-bottom: 70px;
    }

    grid-column: 1 / 6;
    font-style: normal;
    font-weight: 500;
    font-size: var(--etpay-font-size-40);
    line-height: 116%;
    padding-bottom: 85px;
  }

  .box {
    @media (max-width: 1200px) {
      margin-bottom: 25px;
    }
    grid-column: 1 / 3;
    grid-row: 2 / 2;
    height: 70px;
    background: var(--etpay-color-border);
    border-radius: 12px;
  }

  .content {
    @media (max-width: 1200px) {
      grid-column: 1 / 13;
    }

    grid-column: 3 / 10;

    .item {
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      padding: 0 0 60px 0;

      .text {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-13)-1;
          line-height: 110%;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-23);
        line-height: 124%;
      }

      .name {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        padding-top: 10px;
        font-weight: 400;
        font-size: var(--etpay-font-size-15);
        line-height: 34px;
        color: var(--etpay-color-vanth);
      }
    }
  }

  .controls {
    @media (max-width: 1200px) {
      grid-column: 2 / 7;
    }

    grid-column: 11 / 13;

    &-position {
      color: #646174;
      font-size: var(--etpay-font-size-15);
      font-weight: 500;
    }

    &-content {
      display: var(--etpay-display-flex);
      justify-content: space-between;
      align-items: center;

      .icon {
        &-right {
          transform: rotate(-90deg);
        }

        &-left {
          transform: rotate(90deg);
        }
      }
    }
  }
}