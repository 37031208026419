@media (max-width:1200px){
    .payment-link {
        .special-section {
            .column {
                padding-top: 165px;
                padding-bottom: 52px !important;
            }
        }
    }
}

.payment-link {
    .special-section {
        .column {
            padding-top: 165px;
            padding-bottom: 211px;
        }
    }
}