.include-product {
  color: var(--etpay-color-orion);
  padding-top: 125px;
  padding-bottom: 170px;
  position: var(--etpay-position-relative);
  overflow: hidden;

  .back-container {
    position: var(--etpay-position-absolute);
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--etpay-color-sirus);
    z-index: -1;

    &__img {
      position: var(--etpay-position-absolute);
      top: 0;
      right: 0;
      height: 130%;
      transform: translateX(30%);
    }
  }

  .tarifa {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: 2 / 13;
    font-style: normal;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
    padding-bottom: 40px;
    color: var(--etpay-color-secondary);
  }

  .title {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-20);
      line-height: 116%;
    }

    grid-column: 2 / 7;
    font-style: normal;
    font-weight: 500;
    font-size: var(--etpay-font-size-40);
    line-height: 116%;
  }

  .description {
    @media (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
      padding: 24px 0 60px 0;
    }

    grid-column: 7 / 10;
    display: var(--etpay-display-flex);
    align-items: center;
    font-size: var(--etpay-font-size-15);
    line-height: 34px;
  }

  .separator {
    @media (max-width: 1200px) {
      display: none;
      grid-column: 1 / 7;
    }

    grid-column: 2 / 12;
    border-bottom: 2px solid var(--etpay-color-border);
    margin-top: 145px;
    margin-bottom: 35px;
  }

  .bottom {
    &-name {
      @media (max-width: 1200px) {
        grid-column: 2/6;
      }

      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      grid-column: 2 / 4;
    }

    &-title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }

    &-more {
      display: var(--etpay-display-flex);
      column-gap: 15px;
      align-items: center;
      font-size: var(--etpay-font-size-11);
      line-height: 20px;
      cursor: pointer;
    }

    &-description {
      @media (max-width: 1200px) {
        grid-column: 2/6;
        padding: 14px 0 20px 0;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 5 / 10;
      font-size: var(--etpay-font-size-15);
      line-height: 34px;
    }

    &-list {
      @media (max-width: 1200px) {
        grid-column: 2/6;
        font-size: var(--etpay-font-size-08);
        line-height: 120%;
      }

      grid-column: 10 / 13;
      font-size: var(--etpay-font-size-11);
      line-height: 27px;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      row-gap: 3px;

      &-item {
        display: var(--etpay-display-flex);
        align-items: center;
        & > * {
          margin-right: 10px;
        }
      }
    }

    &-separator {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 1 / 13;
      padding-top: 80px;
    }
  }
}