.information-group {
  @media (max-width: 1200px) {
    padding: 120px 0 50px 0;
  }
  position: var(--etpay-position-relative);
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  color: var(--etpay-color-orion);
  padding: 120px 0 150px 0;

  .back-container {
    position: var(--etpay-position-absolute);
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: var(--etpay-color-sirus);
    z-index: -1;

    &__img {
      position: var(--etpay-position-absolute);
      top: 0;
      right: 0;
      height: 100%;
    }
  }

  .top {
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    grid-column: 1 / 7;
    padding-bottom: 40px;

    .top-title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-20);
        line-height: 116%;
        padding: 21px 0;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 114%;
      padding: 35px 0;
    }

    .description {
      @media (max-width: 1200px) {
        padding-bottom: 30px;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }
  }

  .img-back {
    position: var(--etpay-position-absolute);
    z-index: -1;
    top: 0;
    right: 0;
    height: 80%;
  }
}