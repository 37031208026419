.comingSoonContainer {
  background: var(--etpay-color-orion);
  border-radius: 4px;
  width: 92px;
  height: 24px;
  font-weight: 400;
  font-size: var(--etpay-font-size-07);
  line-height: 12px;
  padding: 6px;
  color: var(--etpay-color-sirus);
}