.container {
  display: var(--etpay-display-flex);
  align-items: center;
  width: auto;
  font-size: var(--etpay-font-size-11);
  //line-height: 27px;
  letter-spacing: -0.0008em;
  color: var(--etpay-color-orion);
  min-width: fit-content;

  &>* {
    margin-right: 8px;
  }

  svg {
    flex-shrink: 0;
  }

  p {
    min-width: max-content;
    margin: 4px;
    padding: 2px 0 0 0 !important;
  }

  label {
    font-size: var(--etpay-font-size-07);
    line-height: 12px;
    padding: 6px;
    background: var(--etpay-color-orion);
    border-radius: 4px;
    color: var(--etpay-color-sirus);
  }
}