.App {
  grid-template-areas:
    "header"
    "main"
    "footer";

  & > header {
    grid-area: header;
  }
  & > main {
    grid-area: main;
  }
  & > footer {
    grid-area: footer;
    align-self: end;
  }

}