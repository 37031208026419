.convertion-below {
  @media (max-width: 1200px) {
    margin: 170px 0px;
  }
  margin: 250px 0;

  .right {
    @media (max-width: 1200px) {
      grid-column: 1/7;
    }

    grid-column: 2/7;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    justify-content: center;

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      padding-bottom: 18px;
    }

    .description {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 400;
      font-size: var(--etpay-font-size-13);
      line-height: 34px;
    }
  }

  .left {
    @media (max-width: 1200px) {
      grid-column: 1/7;
    }

    grid-column: 7/13;
    display: var(--etpay-display-flex);
    justify-content: center;
    align-items: flex-end;
  }
}