@import "../../vars";

.card-icon {
    @media (max-width: 1200px) {
        margin: 40px 0;
        width: 70%;
    }

    background: $color-light;
    border: 1px solid var(--etpay-color-ivory);
    border-radius: 24px;
    display: $display-flex;
    flex-direction: var(--etpay-flex-direction-column);
    padding: 40px 20px;
    min-height: 260px;
    transition: all .5s ease-out;
    position: var(--etpay-position-relative);
    z-index: 10;

    &>h3,
    p {
        margin: 6px;
        font-size: 1.25rem;
    }

    &>h3{
        margin-top: 22px;
        font-weight: 500;
    }

    & div {
        justify-content: start !important;
    }
}

.item {
    &__1{
        @media (max-width: 1200px) {
            margin: 40px 0;            
        }
        margin-top: 5%;
    }
    &__2{
        @media (max-width: 1200px) {
            margin: 40px 0 40px 25%;            
        }
        margin-top: 14%;
    }
    &__3{
        @media (max-width: 1200px) {
            margin: 40px 0;
        }
        margin-top: -57%;
    }
    &__4{
        @media (max-width: 1200px) {
            margin: 40px 0 40px 25%;  
        }
        margin-top: 19%;
    }
    &__5{
        @media (max-width: 1200px) {
            margin: 40px 0;
        }
        margin-top: -36%;
    }
    &__6{
        @media (max-width: 1200px) {
            margin: 40px 0 40px 25%;  
        }
        margin-top: 17%;
    }
    &__7{
        @media (max-width: 1200px) {
            margin: 40px 0;
        }
        margin-top: 4%;
    }
    &__8{
        @media (max-width: 1200px) {
            margin: 40px 0 40px 25%;  
        }
        margin-top: 20%;
    }
}

.transform__card {
    transform: translateY(-100%);
}