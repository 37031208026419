@import "../../vars";

.tabs {
    display: $display-flex;
    flex-direction: $flex-direction-row;
    color: var(--etpay-color-pure);

    &__home {
        border-bottom: 1px solid var(--etpay-color-purple);
    }

    &__header {
        padding: 5px 20px;
        min-width: 151px;
        text-align: center;
        cursor: pointer;
        font-size: var(--etpay-font-size-12);
        color: var(--etpay-color-sirus);

        &__home-active {
            border-bottom: 5px solid var(--etpay-color-purple);
        }
    }
}

.tabs__body {
    animation: body__animation .9s linear forwards;
    color: var(--etpay-color-pure);

    & p {
        font-size: var(--etpay-font-size-12);
        color: var(--etpay-color-pure);
    }
    & strong {
        font-weight: 500;
        font-size: var(--etpay-font-size-12);
    }
}

@keyframes body__animation {
    from { opacity: 0 }
    to { opacity: 1 }
}