.conciliation-page {
  position: var(--etpay-position-relative);

  .section1 {

    .text {
      margin-top: 40px;
      padding: 30px 0;
      border-top: 1px solid var(--etpay-color-ivory);
      grid-column: span 4;
    }
  }

  .section2 {
    @media (max-width: 1200px) {
      padding-top: 300px;
      padding-bottom: 100px;
    }

    position: var(--etpay-position-relative);
    padding-bottom: 300px;
    position: relative;

    .list {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 2 / 7;
      z-index: 1;
    }

    .part {
      @media (max-width: 1200px) {
        padding: 20px 0;
      }

      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      width: 100%;
      padding: 200px 0;
      color: var(--etpay-color-secondary);
      text-align: left;
      z-index: 1;

      .title {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-20);
          line-height: 116%;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-26);
        line-height: 114%;
      }

      .top-title,
      .sub-title {
        @media (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        font-size: var(--etpay-font-size-15);
        line-height: 32px;
      }

      .top-title {
        font-weight: 500;
        margin-bottom: 1rem;
      }

      .sub-title {
        font-weight: 400;
        padding: 20px 0 40px 0;
      }

      .leyend {
        padding-top: 80px;

        p {
          color: #646174;
        }
      }
    }

    .animation-container-mobile-back {
      @media (max-width: 1200px) {
        display: flex;
      }

      display: none;
      background: linear-gradient(white 0%, white 90%, transparent 100%);
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      height: 300px;
    }

    .animation-container {
      @media (max-width: 1200px) {
        z-index: 6;
        width: 100%;
        padding-top: 80px;
      }

      position: absolute;
      top:0;
      right:0;

      height: 100%;

      z-index: -1;
      grid-column: 7 / 12;



      .animation {
        @media (max-width: 1200px) {
          margin-top: 0;
          z-index: 10;
        }

        display: var(--etpay-display-flex);
        justify-content: right;
        margin-top: 200px;
        margin-bottom: 120px;

        .box-container {
          top: 200px;
          display: var(--etpay-display-grid);
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          transform: rotate(30deg);

          .box {
            @media (max-width: 1200px) {
              width: 70px;
              height: 70px;
            }

            border-radius: 12px;
            width: 133px;
            height: 133px;
            background: var(--etpay-color-secondary);
            z-index: 2;

            &-light {
              opacity: .2;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}

.bottom-more {
  margin-top: 18px;
}