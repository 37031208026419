.ready-to-start {
  @media (max-width: 1200px) {
    padding-bottom: 0;
    padding-top: 87px;
  }

  color: var(--etpay-color-orion);
  padding-top: 140px;
  padding-bottom: 170px;


  .left {
    @media (max-width: 1200px) {
      padding-bottom: 130px;
      grid-column: 1 / 7;
    }

    grid-column: 1 / 6;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);    

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-20);
        line-height: 100%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-40);
      line-height: 100%;
    }

    .description {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
        width: 100%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      width: 75%;
    }

    .buttons {
      @media (max-width: 1200px) {
        flex-direction: var(--etpay-flex-direction-column);
        row-gap: 12px;
      }

      display: var(--etpay-display-flex);
      column-gap: 12px;
    }

    &>*{
      margin-bottom: 41px;
    }
  }

  .right {
    @media (max-width: 1200px) {
      grid-column: span 6;
      align-items: flex-start;
      padding-bottom: 100px;
    }

    grid-column: span 3;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    justify-content: center;

    .sign {
      display: flex;
      justify-content: start;
    }

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
        width: 100%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      padding-top: 20px;
      margin-top: 20px;
      border-top: 1px solid var(--etpay-color-border);
    }

    .description {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-weight: 400;
      font-size: var(--etpay-font-size-13-1);
      line-height: 34px;
      padding: 5px 0 25px 0;
    }

    .link {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-10);
        line-height: 18px;
      }

      cursor: pointer;
      font-weight: 400;
      font-size: var(--etpay-font-size-11);
      line-height: 20px;
      display: var(--etpay-display-flex);
      align-items: center;
      column-gap: 15px;
    }
  }
}