.developers-page {
  background-color: var(--etpay-color-secondary);
  color: var(--etpay-color-sirus);

  .banner {
    @media (max-width: 1200px) {
      padding-top: 91px;
    }

    background-color: #140F33CC;
    padding-top: 100px;
    padding-bottom: 350px;
    position: var(--etpay-position-relative);
    overflow: hidden;

    .img-figure,
    .img-texture {
      @media (max-width: 1200px) {
        right: auto;
      }

      position: var(--etpay-position-absolute);
      top: 50px;
      right: 0;
      height: 110%;
      opacity: .4;
    }

    .img-texture {
      mix-blend-mode: soft-light;
    }

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-21);
        line-height: 114%;
      }

      z-index: 1;
      grid-column: 1 / 13;
      text-align: center;
      font-weight: 500;
      font-size: var(--etpay-font-size-63);
      line-height: 100%;
      color: var(--etpay-color-sirus);
    }

    .description {
      @media (max-width: 1200px) {
        grid-column: 1 / 13;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
        padding: 14px 0 33px 0;
      }

      z-index: 1;
      color: var(--etpay-color-sirus);
      grid-column: 4 / 10;
      text-align: center;
      font-size: var(--etpay-font-size-15);
      line-height: 34px;
      padding: 35px 0 50px 0;
    }

    .link {
      z-index: 1;
      grid-column: 1 / 13;
      display: var(--etpay-display-flex);
      justify-content: center;
    }
  }

  .section1 {
    text-align: left;
    padding-top: 380px;
    position: var(--etpay-position-relative);

    .console {
      position: var(--etpay-position-absolute);
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .performance,
    .title,
    .description {
      @media (max-width: 1200px) {
        grid-column: 1 / 13;
      }

      color: var(--etpay-color-sirus);
      grid-column: 3 / 10;
    }

    .performance,
    .description {
      @media (max-width: 1200px) {
        grid-column: 1 / 13;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 3 / 10;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }

    .performance {
      color: var(--etpay-color-purple);
    }

    .title {
      @media (max-width: 1200px) {
        font-size: var(--etpay-font-size-20);
        line-height: 116%;
        padding: 21px 0;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-40);
      line-height: 116%;
      padding: 40px 0;
    }
  }

  .section2 {
    @media screen and (max-width: 1200px) {
      padding-bottom: 0;
    }

    padding-bottom: 300px;
    position: var(--etpay-position-relative);

    .img-figure {
      position: var(--etpay-position-absolute);
      bottom: 450px;
      left: 0;
      width: 30%;
      opacity: .05;
      z-index: 1;

      img {
        width: 100%;
      }

      &::after {
        content: '';
        background: linear-gradient(rgba(0, 0, 0, 0), var(--etpay-color-secondary));
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 200px;
      }
    }

    .list {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 3 / 7;
    }

    .part {
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      width: 100%;
      padding: 50px 0;
      text-align: left;
      z-index: 1;

      &>* {
        margin-bottom: 20px;
      }

      .title {
        @media screen and (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-26);
        line-height: 114%;
        color: var(--etpay-color-sirus);
      }

      .top-title,
      .sub-title {
        @media screen and (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        color: var(--etpay-color-sirus);
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
      }

      .top-title {
        font-weight: 500;
      }

      .sub-title {
        font-weight: 400;
      }

      .bank-list {
        .name {
          color: white !important;
        }
      }
    }
  }

  .animation {
    @media screen and (max-width: 1200px) {
      display: none
    }

    grid-column: 7 / 12;
    display: var(--etpay-display-flex);
    justify-content: right;
    margin-top: 160px;

    .web {
      position: var(--etpay-position-absolute);
      right: 300px;
      filter: drop-shadow(0 0 1px black);

      &-back {
        background: var(--etpay-color-secondary);
        width: 400px;
        height: 400px;
        position: var(--etpay-position-absolute);
        clip-path: polygon(50% 0, 87% 61%, 32% 100%, 0% 45%);
        transform: translate(-200px, -160px) scale(1);
        transform-origin: center center;

        &-circle {
          background: var(--etpay-color-secondary);
          width: 250px;
          height: 250px;
          position: var(--etpay-position-absolute);
          border-radius: 100%;
          transform: translate(-110px, -110px) scale(0);
        }
      }

      &-line {
        position: var(--etpay-position-absolute);
        z-index: 1;
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        transform-origin: 0 0;
        height: 1px;

        &.line-1 {
          transform: translate(20px, 20px) rotate(30deg);
          width: 150px;
        }

        &.line-2 {
          transform: translate(20px, 20px) rotate(-97deg);
          width: 150px;
        }

        &.line-3 {
          transform: translate(20px, 20px) rotate(-181deg);
          width: 200px;
        }

        &.line-4 {
          transform: translate(20px, 20px) rotate(113deg);
          width: 220px;
        }
      }

      &-dot {
        position: var(--etpay-position-absolute);
        background: var(--etpay-color-orion);
        border-radius: 100%;
        height: 42px;
        width: 42px;
        z-index: 2;

        &.dot-2 {
          transform: translate(-20px, -170px);
        }

        &.dot-3 {
          transform: translate(125px, 73px);
        }

        &.dot-4 {
          transform: translate(-90px, 204px);
        }

        &.dot-5 {
          transform: translate(-204px, 0px);
        }
      }
    }
  }

  .section3 {
    background: var(--etpay-color-orion);
    padding-top: 115px;
    padding-bottom: 150px;

    .top-title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 3/8;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      color: var(--etpay-color-purple);
    }

    .title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-20);
        line-height: 116%;
        padding: 21px 0;
      }

      color: var(--etpay-color-sirus);
      grid-column: 3/8;
      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 114%;
      padding: 35px 0;
    }

    .description {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;

      }

      color: var(--etpay-color-sirus);
      grid-column: 3/8;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      padding-bottom: 55px;
    }

    .item {
      @media screen and (max-width: 1200px) {
        grid-row: auto;
        padding-bottom: 65px;
      }

      grid-row: 4;
      display: var(--etpay-display-flex);

      &.first {
        @media screen and (max-width: 1200px) {
          grid-column: 1/7;
        }

        grid-column: 3 / 7;
      }

      &.second {
        @media screen and (max-width: 1200px) {
          grid-column: 1/7;
        }

        grid-column: 7 / 11;
      }

      .content {
        display: var(--etpay-display-flex);
        flex-direction: var(--etpay-flex-direction-column);

        .title,
        .description {
          @media screen and (max-width: 1200px) {
            font-size: var(--etpay-font-size-12);
            line-height: 28px;
          }

          font-size: var(--etpay-font-size-12);
          line-height: 34px;
          padding: 0;
          font-weight: 400;
        }

        .description {
          color: var(--etpay-color-moon);
        }

        .link {
          @media screen and (max-width: 1200px) {
            font-size: var(--etpay-font-size-10);
            line-height: 18px;
          }

          font-size: var(--etpay-font-size-11);
          line-height: 20px;
          color: var(--etpay-color-ivory);
          padding-top: 8px;
          display: var(--etpay-display-flex);
          align-items: center;
          column-gap: 15px;
        }
      }
    }
  }
}