.slider-item {
  @media (max-width: 1200px) {
    //width: 215px;
    height: 300px;
    //min-width: 215px;
    min-height: 260px;
    padding: 20px;
    width: 280px !important;
  }

  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  justify-content: space-between;
  //width: 555px;
  height: 673px;
  //min-width: 555px;
  min-height: 673px;
  border-radius: 24px;
  padding: 2.5rem;
  background-color: var(--etpay-color-ivory);
  background-position: center;
  background-size: cover;
  position: var(--etpay-position-relative);

  &.active {
    &.paymentlink {

      .title {
        color: var(--etpay-color-secondary);
      }

      .back {
        background-color: var(--etpay-color-purple);
      }
    }

    &.quicktransfer {
      .title {
        color: var(--etpay-color-canes);
      }

      .back {
        background-color: #ff7576;
      }
    }
  }

  .title {
    @media (max-width: 1200px) {
      font-size: var(--etpay-font-size-15);
      line-height: 110%;
      padding-bottom: 5px;
    }

    font-weight: 500;
    font-size: var(--etpay-font-size-26);
    line-height: 114%;
    color: var(--etpay-color-sirus);
    padding-bottom: 34px;
    z-index: 1;
  }

  .center {
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    height: 100%;
    z-index: 2;

    @media (max-width: 1200px) {
      overflow-y: auto;
      padding-bottom: 5px;
    }

    .service {
      @media (max-width: 1200px) {
        display: none;
      }

      display: var(--etpay-display-flex);
      align-items: center;
      column-gap: 13px;

      .icon {
        background-color: var(--etpay-color-border);
        width: 42px;
        height: 42px;
        border-radius: 5.92881px;
        display: var(--etpay-display-flex);
        justify-content: center;
        align-items: center;
      }

      .text {
        @media (max-width: 1200px) {
          font-size: 14px;
          line-height: normal;
        }

        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: var(--etpay-color-sirus);
      }
    }

    .description {
      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: normal;
        padding-top: 5px;
        overflow-y: auto;
      }

      color: var(--etpay-color-sirus);
      font-size: 22px;
      line-height: 34px;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      padding-top: 15px;

      p {
        @media (max-width: 1200px) {
          margin: 5px;
        }

        padding: 0;
        margin: 15px 0;
      }
    }

  }

  .btn {
    z-index: 1;
  }

  .back {
    position: var(--etpay-position-absolute);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
  }
}