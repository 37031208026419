.index_container {
  position: var(--etpay-position-relative);
  display: var(--etpay-display-grid);
  grid-template-areas: 'menu content';
  grid-template-columns: var(--etpay-grid-template-columns);
  column-gap: var(--etpay-grid-gap);

  &__navigation_menu {
    grid-area: menu;
    grid-column: span 4;
    transition: all 0.3s ease-in-out;

    &__content {
      position: sticky;
      display: var(--etpay-display-flex);
      column-gap: 24px;
      flex-direction: var(--etpay-flex-direction-column);
      justify-content: flex-start;

      &__item {
        position: var(--etpay-position-relative);
        font-style: normal;
        font-weight: 500;
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
        letter-spacing: -0.0008em;
        margin-bottom: 24px;
        color: var(--etpay-color-moon);

        &::after {
          background: var(--etpay-color-moon);
          content: '';
          position: var(--etpay-position-absolute);
          right: 0;
          top: calc(50% - 1px);
          height: 2px;
          width: 20%;
          transition: all 0.3s ease-in-out;
        }

        &:hover {
          cursor: pointer;
        }

        &.active {
          color: var(--etpay-color-orion);

          &::after {
            background: var(--etpay-color-orion);
            width: 25%;
          }

          a {
            color: var(--etpay-color-orion);
            font-weight: 500;
          }
        }

        &__text {
          max-width: 290px;
        }

        a {
          text-decoration: none;
          display: block;
          transition: all 50ms ease-in-out;
          font-style: normal;
          font-weight: 400;
          font-size: var(--etpay-font-size-10);
          line-height: 34px;
          letter-spacing: -0.0008em;
          color: var(--etpay-color-moon);
        }
      }
    }
  }

  &__content {
    grid-area: content;
    grid-column: span 8;

    &__section {
      margin-bottom: 124px;

      &:last-child {
        margin-bottom: 0px;
      }

      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: var(--etpay-font-size-23);
        line-height: 124%;
        letter-spacing: -0.0008em;
        color: var(--etpay-color-orion);
        flex: none;
        order: 0;
        flex-grow: 0;
        color: var(--etpay-color-orion);

        // clear browser styles
        margin-block-start: 0em;
        margin-block-end: 34px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      p,
      ul,
      ol {
        font-style: normal;
        font-weight: 400;
        font-size: var(--etpay-font-size-15);
        line-height: 34px;
        letter-spacing: -0.0008em;
        color: var(--etpay-color-orion);
      }

      p {
        // clear browser styles
        margin: 0;
        padding: 0;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      ul {
        list-style: none;
      }

      ol {
        &.nested-list {
          counter-reset: item;

          li {
            display: block;

            &::before {
              content: counters(item, ".") ". ";
              counter-increment: item;
              font-weight: bold;
            }
          }

          ol {
            counter-reset: item;

            &>li {
              display: block;

              &::before {
                content: counters(item, ".") " ";
                counter-increment: item;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}

// =============================================================================
// == Responsive breakpoints
// =============================================================================

@media screen and (max-width: 1024px) {
  .index_container {

    &__navigation_menu {
      display: none;
    }

    &__content {
      grid-area: content;
      grid-column: span 12;
    }
  }
}

@media screen and (max-width: 768px) {
  .index_container {
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 9px;

    &__navigation_menu {}

    &__content {
      grid-area: content;
      grid-column: span 6;

      h2 {
        font-weight: 500;
        font-size: var(--etpay-font-size-13)-1;
        line-height: 110%;
      }

      p,
      ul,
      li {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
        letter-spacing: 0.003em;
      }
    }
  }
}