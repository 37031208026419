.returns-page {

  .section2 {
    @media (max-width: 1200px) {
      padding-top: 300px;
    }

    position: var(--etpay-position-relative);

    .list {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 2 / 7;
      z-index: 1;
    }

    .part {
      @media (max-width: 1200px) {
        height: 550px;
      }

      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      justify-content: center;
      row-gap: 20px;
      width: 100%;
      height: 630px;
      //padding: 200px 0;
      color: var(--etpay-color-orion);
      text-align: left;
      z-index: 1;

      .title {
        font-weight: 500;
        font-size: var(--etpay-font-size-26);
        line-height: 114%;
      }

      .top-title,
      .sub-title {
        font-size: var(--etpay-font-size-15);
        line-height: 32px;

        &.red {
          color: var(--etpay-color-canes);
        }
      }

      .top-title {
        font-weight: 500;
      }

      .sub-title {
        font-weight: 400;
      }

      .bank-list {
        display: var(--etpay-display-grid);
        grid-auto-flow: column;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(7, 1fr);
        column-gap: 1rem;

        .item {
          display: var(--etpay-display-flex);
          align-items: center;
          column-gap: .5rem;

          .name {
            font-size: var(--etpay-font-size-11);
            line-height: 27px;
            min-width: fit-content;
          }

          .new {
            font-size: var(--etpay-font-size-07);
            line-height: 12px;
            padding: 6px;
            background: var(--etpay-color-orion);
            border-radius: 4px;
            color: var(--etpay-color-sirus);
          }
        }
      }
    }

    .animation-container-mobile {
      @media (max-width: 1200px) {
        display: flex;
      }

      display: none;
      background: linear-gradient(white 0%, white 90%, transparent 100%);
      position: absolute;
      top: 0 !important;
      left: 0 !important;
      width: 100vw;
      min-height: 400px;
      z-index: 1;
    }

    .animation-container {
      @media (max-width: 1200px) {
        right: -120px;
        top: -40px;
        z-index: 2;
      }

      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      grid-column: 7 / 12;


      .circle {
        position: absolute;
        border-radius: 100%;

        &-1 {
          width: 320px;
          height: 320px;
          background-color: var(--etpay-color-canes);
          top: 0;
          right: 0;
          z-index: 1;
        }

        &-2 {
          width: 250px;
          height: 250px;
          background-color: #ded4da;
          top: 200px;
          right: 350px;
          z-index: 3;
        }

        &-3 {
          width: 110px;
          height: 110px;
          background-color: var(--etpay-color-canes);
          top: 400px;
          right: 700px;
          z-index: 5;
        }

        &-white {
          border-radius: 100%;
          background-color: white;
          transform: scale(0);

          &-1 {
            width: 270px;
            height: 270px;
            top: 75px;
            right: 175px;
            z-index: 2;
          }

          &-2 {
            width: 130px;
            height: 130px;
            top: 145px;
            right: 245px;
            z-index: 4;
          }

          &-3 {
            width: 50px;
            height: 50px;
            top: 185px;
            right: 285px;
            z-index: 6;
          }
        }
      }
    }
  }
}