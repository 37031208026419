.information-group-item {
  @media (max-width: 1200px) {
    grid-column: 2/6;
    padding-bottom: 50px;
  }

  grid-column: span 3;
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);

  .icon {
    width: 85px;
    height: 85px;
    background: var(--etpay-color-border);
    border-radius: 100%;
    display: var(--etpay-display-flex);
    justify-content: center;
    align-items: center;

    &-item {
      transform: translateY(3px);
    }
  }

  .description {
    @media (max-width: 1200px) {
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    border-top: 1px solid var(--etpay-color-border);
    margin-top: 25px;
    padding-top: 30px;
    font-size: var(--etpay-font-size-15);
    line-height: 34px;
  }
}