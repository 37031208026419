@import "../../vars";

.faqs {
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  color: var(--etpay-color-orion);

  .banner {
    padding: 96px 0;

    .back-icon {
      position: absolute;
      transform: scaleX(-100%);
      z-index: -1;
      left: 30%;
    }

    @media (max-width:1200px) {
      .back-icon {
        position: absolute;
        transform: scaleX(400%);
        z-index: -1;
        left: 0;
        width: 40%;
        height: 100%;
      }
    }
    .title {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-21);
        line-height: 114%;
      }

      grid-column: 1 / 13;
      text-align: center;
      font-weight: 500;
      font-size: var(--etpay-font-size-63);
      line-height: 100%;
    }

    .description {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 4 / 10;
      text-align: center;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      padding: 37px 20px 56px 20px;
    }

    .select {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 1 / 13;
      display: var(--etpay-display-flex);
      justify-content: center;
    }

    .tab-box {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        margin-top: 60px;
      }

      grid-column: 2 / 12;
      margin-top: 96px;
    }

  }
}