@import "../../vars";

.prices {
  color: var(--etpay-color-orion);

  .banner {
    padding-top: 96px;
    padding-bottom: 96px;

    .back-icon {
      position: absolute;
      z-index: -1;
      left: -50%;
      width: 170vw;
      top: 5%;
    }

    @media (max-width:1280px) {
      .back-icon {
        position: absolute;
        z-index: -1;
        left: 0;
        width: 100%;
        top: 5%;
      }
    }

    .title {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-21);
        line-height: 114%;
      }

      grid-column: 1 / 13;
      text-align: center;
      font-weight: 500;
      font-size: var(--etpay-font-size-63);
      line-height: 100%;
    }

    .description {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
        padding-bottom: 40px;
        padding-top: 14px;
      }

      grid-column: 4 / 10;
      text-align: center;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      padding: 37px 20px 56px 20px;    
    }

    .select {
      @media (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 1 / 13;
      display: var(--etpay-display-flex);
      justify-content: center;
    }

    .tab-box {
      @media (max-width: 1200px) {
        grid-column: 1/7;
        margin-top: 66px;
      }

      grid-column: 2 / 12;
      margin-top: 96px;
    }

  }

  .bottom {
    @media screen and (max-width: 1200px) {
      padding-bottom: 75px;
    }
    padding-bottom: 150px;

    .title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 2 / 12;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
    }

    .description {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 2 / 7;
      font-size: var(--etpay-font-size-15);
      line-height: 34px;
      padding: 18px 0 42px 0;
    }

    .span {
      @media screen and (max-width: 1200px) {
        display: none;
      }

      grid-row: 3;
    }

    .list {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        grid-row: auto;
        font-size: var(--etpay-font-size-08);
        line-height: 120%;
        row-gap: 15px;
        padding-bottom: 15px;
      }

      grid-row: 3;
      grid-column: span 3;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);

      .item {
        display: var(--etpay-display-flex);
        align-items: center;
        column-gap: 11px;
      }
    }
  }
}