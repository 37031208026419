@import "../../vars";

.column-container {
    width: 100vw;
    display: var(--etpay-display-flex);
    justify-content: center;
}

.column {
    @media (max-width: 1200px) {
        grid-template-columns: $grid-columns-6;
        column-gap: $grid-gap-8;
        padding: 0 $tablet-grid-gap;
    }

    display: $display-grid;
    grid-template-columns: $grid-columns-12;
    column-gap: $grid-gap-32;
    max-width: 1376px;
    margin: auto;
    padding: 0 $grid-gap-32;
}