@import '../../vars';

.press-brand {
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  color: var(--etpay-color-orion);

  .banner {
    position: var(--etpay-position-relative);
    padding-top: 80px;

    .back {
      position: var(--etpay-position-absolute);
      left: 0;
      top: 0;
      z-index: -1;
      opacity: .2;
      height: 250%;
      transform: translateX(-30%);
    }

    .title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-26);
      }

      grid-column: 2/13;
      font-weight: 500;
      font-size: var(--etpay-font-size-63);
      line-height: 100%;
      padding-bottom: 36px;
    }

    
  }
  .description {
    @media screen and (max-width: 1200px) {
      grid-column: 1/7;
      font-size: var(--etpay-font-size-10);
    }

    grid-column: 2/11;
    font-weight: 500;
    font-size: var(--etpay-font-size-11);
    line-height: 124%;
  }
  .bottom-banner {
    @media screen and (max-width: 1200px) {
      padding-top: 94px;
    }

    position: var(--etpay-position-relative);
    padding-top: 240px;

    .top-title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 2/7;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;
      color: var(--etpay-color-less-brown);
    }

    .title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        font-size: var(--etpay-font-size-20);
      }

      grid-column: 2/13;
      font-weight: 500;
      font-size: var(--etpay-font-size-26);
      line-height: 114%;
      padding: 34px 0;
    }

    .sub-title {
      @media screen and (max-width: 1200px) {
        grid-column: 1/6;
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      grid-column: 2/6;
      font-weight: 500;
      font-size: var(--etpay-font-size-15);
      line-height: 32px;

      .email {
        color: var(--etpay-color-less-brown);
      }
    }
  }

  .info {
    @media screen and (max-width: 1200px) {
      padding-top: 100px;
      padding-bottom: 10px;
    }

    padding-top: 200px;
    padding-bottom: 120px;

    .item {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        padding-bottom: 50px;
      }

      grid-column: span 4;

      .icon {
        height: 83px;
        padding-bottom: 28px;
        display: var(--etpay-display-flex);
        align-items: flex-end;

        .img {
          max-height: 55px;
        }
      }

      .description {
        @media screen and (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        padding-top: 28px;
        border-top: 1px solid var(--etpay-color-border);
        font-weight: 400;
        font-size: var(--etpay-font-size-15);
        line-height: 34px;
      }
    }
  }

  .marca {
    @media screen and (max-width: 1200px) {
      padding-bottom: 132px;
    }
    background-image: url('../../../public/banners/ETpay-grad_07_FC.jpg');
    background-position: 0px 2rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: var(--etpay-position-relative);
    padding-bottom: 190px;
    padding-top: 190px;
    background-color: var(--etpay-color-sirus);

    .back {
      position: var(--etpay-position-absolute);
      left: 50%;
      top: 120px;
      z-index: 0;
      transform: translateX(-50%);
    }

    @media (max-width:1200px){
      .back {
        z-index: 0;
        transform: translateX(-50%);
        width: 100%;
      }
    }

    .left {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
      }

      grid-column: 2/7;
      display: var(--etpay-display-flex);
      flex-direction: var(--etpay-flex-direction-column);
      position: var(--etpay-position-relative);

      .top-title {
        @media screen and (max-width: 1200px) {
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
        color: var(--etpay-color-less-brown);
      }

      .title {
        @media screen and (max-width: 1200px) {
          font-size: var(--etpay-font-size-20);
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-26);
        line-height: 114%;
        padding: 34px 0;
      }

      .sub-title {
        @media screen and (max-width: 1200px) {
          grid-column: 1/6;
          font-size: var(--etpay-font-size-11);
          line-height: 24px;
          width: 100%;
        }

        font-weight: 500;
        font-size: var(--etpay-font-size-15);
        line-height: 32px;
        width: 80%;
      }
    }

    .right {
      @media screen and (max-width: 1200px) {
        grid-column: 1/7;
        grid-template-columns: 1fr;
        row-gap: 50px;
      }

      grid-column: 8/12;
      display: var(--etpay-display-grid);
      grid-template-columns: 1fr 1fr;
      column-gap: $grid-gap-32;
      padding-top: 15px;


      .item {
        display: var(--etpay-display-flex);
        flex-direction: var(--etpay-flex-direction-column);
        row-gap: 27px;
        transform: translateY(50px);

        &>* {
          margin-bottom: 27px;
        }

        & button {
          &:hover {
            .line-vertical {
              fill: var(--etpay-color-pure) !important;
              stroke: var(--etpay-color-pure) !important;
            }
          }
        }

        .img {
          @media screen and (max-width: 1200px) {
            height: auto;
          }

          width: 100%;
          height: 100px;
          background: var(--etpay-color-orion);
          border-radius: 12px;

          &.padding {
            padding: 20px;
          }
        }
      }
    }
  }
}