@import "../../vars";

.container_special_section {
    @media (max-width: 1200px) {
        grid-column: 1/7;
        order: -1;
        height: 536px;
        margin-bottom: 170px;
    }

    grid-column: 7 / 12;
    width: 100%;
    height: 50%;
    min-height: 456px;    
    position: $position-relative;

    .container-console {
        background: var(--etpay-color-half-black);
        border: 1px solid var(--etpay-color-purple);
        border-radius: 25px;
        position: var(--etpay-position-relative);
        height: 100%;
        z-index: 1;   
        padding-right: 10px;     

        .header{
            display: $display-flex;
            flex-direction: var(--etpay-flex-direction-row);        
            padding: 10px;
            & > div {
                color: var(--etpay-color-purple);
                padding: 10px;
                font-size: var(--etpay-font-size-11);
                border-radius: 12px;
                cursor: pointer;
                transition: all 0.5s linear;
                margin: 0px 3px;

                & span {
                    color: var(--etpay-color-purple);
                }
                &:hover {
                    background-color: var(--etpay-color-secondary);                    
                }
            }
        }

        .body {
            display: $display-flex;
            flex-direction: var(--etpay-flex-direction-column);
            padding: 0px 25px 20px 25px;  
            transition: all .5s ease-in-out; 
            width: 100%;
            height: 80%;
            overflow-y: auto;
            overflow-x: hidden;         


            & > div {
                color: $color-light;
                display: $display-flex;
                flex-direction: var(--etpay-flex-direction-row);
                align-items:flex-start;
                margin: 5px 0px;
                font-size: var(--etpay-font-size-11);
                font-weight: 400;
                line-height: 32px;
                letter-spacing: -0.0208em;
                
                & > span {
                    color: var(--etpay-color-less-brown);
                }

                & > p {
                    width: 100%;
                    box-sizing: content-box;
                    word-break:break-all;
                    margin: 0px;
                    padding-left: 25px;
                    color: #B7B7B7;
                }
            }
            &::-webkit-scrollbar {
                width: 12px;
                height: 100%;                
            }
            &::-webkit-scrollbar-track {
                background: rgba(0, 0, 0, 0);
            }
            &::-webkit-scrollbar-thumb {
                background: var(--etpay-color-vanth);
                border-radius: 25px;
            }
        }        
    }


    .phone {
        position: $position-absolute;
        top: 15%;
        left: 40%;
    }
}

.phone_in {
    z-index: 50 !important;
    transform: translateX(-50%)
}

.phone_out {
    @media (max-width: 1200px) {
        transform: translateX(-30%)
    }
    z-index: 0 !important;
    transform: translateX(0%)
}

.animate_phone_in {
    @media (max-width: 1200px) {
        animation: animation_phone_in_movil 1s linear forwards;    
    }
    animation: animation_phone_in 1s linear forwards;
}

@keyframes animation_phone_in_movil {
    0% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-30%);
    }
}

@keyframes animation_phone_in {
    0% {
        transform: translateX(-50%);
    }
    50% {
        transform: translateX(110%);
    }
    100% {
        transform: translateX(0%);
    }
}

.animate_phone_out {
    @media (max-width: 1200px) {
        animation: animation_phone_out_movil 1s linear forwards;    
    }
    animation: animation_phone_out 1s linear forwards;
}

@keyframes animation_phone_out_movil {
    0% {
        transform: translateX(-30%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-50%);
    }
}

@keyframes animation_phone_out {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(110%);
    }
    100% {
        transform: translateX(-50%);
    }
}