.privacy-page {
  position: relative;
  padding-bottom: 532px;
  @media (max-width: 1200px) {
    overflow-x: hidden;
  }
  .banner-section {
    padding-top: 77px;
    padding-bottom: 157px;
  }
}