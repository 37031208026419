.bank-information {
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  background: var(--etpay-color-purple);
  border-radius: 34px;
  padding: 33px;
  height: 586px;
  position: relative;

  .title {
    font-weight: 500;
    font-size: var(--etpay-font-size-11);
    line-height: 32px;
    color: var(--etpay-color-secondary);
  }

  .description {
    font-size: var(--etpay-font-size-11);
    line-height: 28px;
    color: var(--etpay-color-sirus);
    padding: 15px 0;
  }

  &__list {
    overflow-y: auto;
    font-size: var(--etpay-font-size-15);
    line-height: 36px;
    color: var(--etpay-color-sirus);
    position: var(--etpay-position-relative);

    .item {
      border-bottom: 1px solid var(--etpay-color-sirus);
      margin-bottom: 8px;
      color: var(--etpay-color-sirus);

      &:last-of-type {
        border-bottom: none;
      }

    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .deg {
    min-height: 50px;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1;
    transition: .3s;

    &.top {
      background: #857BF9;
      filter: blur(2px);
      opacity: .5;
      margin-bottom: -50px;
    }

    &.bottom {
      margin-top: -50px;
      background: #857BF9;
      filter: blur(2px);
      -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      opacity: .5;
      -moz-opacity:0.5;
      -khtml-opacity: 0.5;

    }

    &.hidden {
      display: flex;
      opacity: 0;
    }
  }

}