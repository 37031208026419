@import "../../vars";

.tab-box {
  @media screen and (max-width: 1200px) {
    padding: 36px 18px;
  }

  min-width: 100%;
  display: var(--etpay-display-flex);
  flex-direction: var(--etpay-flex-direction-column);
  background-color: white;
  box-shadow: 2px 0px 34px rgba(88, 87, 87, 0.14);
  border-radius: 12px;
  padding: 65px;

  .tabs-content {
    display: flex;
    justify-content: center;
  }

  .tabs {
    @media screen and (max-width: 1200px) {
      justify-content: flex-start;
    }

    display: var(--etpay-display-flex);
    justify-content: center;
    column-gap: 5px;
    overflow-x: auto;
    padding-bottom: 40px;
    margin-bottom: 50px;
    max-width: 100vw;
    background: var(--etpay-color-sirus);
    border-radius: 12px;
    padding: 8px;

    .tab {
      &.active {
        background-color: var(--etpay-color-orion);
        color: white;
      }
    }
  }
}

.tab-content {
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(7, 1fr);
    column-gap: 0;
  }

  display: var(--etpay-display-grid);
  grid-template-columns: repeat(10, 1fr);
  column-gap: $grid-gap-32;
  row-gap: 8rem;

  &__title {
    @media screen and (max-width: 1200px) {
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    grid-column: span 3;
    font-weight: 500;
    font-size: var(--etpay-font-size-15);
    line-height: 32px;
    text-align: left;
  }

  &__text {
    grid-column: span 4;

    &--title {
      padding-bottom: 16px;
    }
  }

  &__description {
    @media screen and (max-width: 1200px) {
      font-size: var(--etpay-font-size-11);
      line-height: 24px;
    }

    text-align: left;
    font-size: var(--etpay-font-size-15);
    line-height: 34px;
    padding: 0;
  }

  &__value {

    grid-column: span 3;
    text-align: right;
    display: var(--etpay-display-flex);
    flex-direction: var(--etpay-flex-direction-column);
    align-items: flex-end;

    &.end {
      @media screen and (max-width: 1200px) {
        justify-content: flex-start;
      }

      justify-content: flex-end;
    }

    &--top {
      @media screen and (max-width: 1200px) {
        font-size: var(--etpay-font-size-13)-1;
        line-height: 110%;
      }

      font-weight: 500;
      font-size: var(--etpay-font-size-23);
      line-height: 124%;
    }

    &--bottom {
      @media screen and (max-width: 1200px) {
        font-size: var(--etpay-font-size-11);
        line-height: 24px;
      }

      font-size: var(--etpay-font-size-15);
      line-height: 24px;
    }

    button {
      @media screen and (max-width: 1200px) {
        font-size: var(--etpay-font-size-10);
        line-height: 18px;
      }
    }
  }
}